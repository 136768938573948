import { Icon } from "@iconify/react";
import { Box, Link, Typography } from "@mui/material";
import { IEvaluationsEnum } from "@netcero/netcero-core-api-client";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { useEvaluationLink } from "./hooks/evaluation-link.hook";
import { EvaluationIcon } from "./evaluation-icon.component";

interface IEvaluationListItemProps {
  organizationId: string;
  recordingPeriodId: string;
  inputParameterRecordingStructureId: string;
  evaluationKey: IEvaluationsEnum;
  disabled?: boolean;
}

export const EvaluationListItem: FC<IEvaluationListItemProps> = ({
  organizationId,
  recordingPeriodId,
  inputParameterRecordingStructureId,
  evaluationKey,
  disabled,
}) => {
  const { t } = useTranslation("evaluation_list_item");

  const linkUrl = useEvaluationLink({
    organizationId,
    recordingPeriodId,
    evaluation: evaluationKey,
    recordingStructureId: inputParameterRecordingStructureId,
  });

  const content = (
    <Box display="flex" gap={1} alignItems="center">
      <EvaluationIcon isEvaluation={evaluationKey === IEvaluationsEnum.Emissions} />
      <Typography mr="auto">{t(`evaluation_name.${evaluationKey}`)}</Typography>
      <Icon icon="ci:caret-right-sm" height="3rem" width="3rem" />
    </Box>
  );

  return disabled ? (
    content
  ) : (
    <Link to={linkUrl} underline="none" color="inherit" component={RouterLink}>
      {content}
    </Link>
  );
};
