import { FC, useCallback, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { IESRSTopic } from "@netcero/netcero-core-api-client";
import {
  IFilterIROs,
  IROsOverviewList,
} from "../../double-materiality-assessment/impacts-risks-opportunities/components/iros-overview-list.component";
import { useTranslation } from "react-i18next";
import { IIrosListEntry } from "../../double-materiality-assessment/impacts-risks-opportunities/impacts-risks-opportunitites.types";
import { IRO_TYPES } from "@netcero/netcero-common";
import { IROsESRSTopicsTabs } from "../../data-entry-object-values/esrs/fill-in-helpers/iros-esrs-topics-tabs.component";
import { FilterSelect } from "../../filters/filter-select.component";
import { FilterType, IFilterConfig } from "../../filters/filter.type";
import { IrosListFilter } from "../../filters/filter.constants";
import { getFilterConfigGenerator } from "../../filters/filter-bar.component";
import { EffectAreaType, Materiality, FilterValues } from "./dma-iro-overview-list.types";
import { DMAOverviewTableUtilities } from "../dma-overview-table/dma-overview-table.utilities";

interface IDmaIroOverviewListProps {
  esrsTopics: IESRSTopic[];
}

export const DmaIroOverviewList: FC<IDmaIroOverviewListProps> = ({ esrsTopics }) => {
  const { t } = useTranslation("dma_dashboard");

  const [selectedFilters, setSelectedFilters] = useState({
    [IrosListFilter.EsrsTopic]: esrsTopics[0]?.identifier ?? null,
    [IrosListFilter.IroType]: [],
    [IrosListFilter.EffectAreaType]: [],
    [IrosListFilter.Materiality]: [],
  });

  const filterIroTypeOptions = useMemo(
    () =>
      IRO_TYPES.map((type) => ({
        value: type,
        name: t(`plural.${type}`, { ns: "iro_types" }),
      })),
    [t],
  );
  const filterAffectedAreaOptions = useMemo(
    () =>
      Object.values(EffectAreaType).map((area) => ({
        value: area,
        name: t(`${area}_name`, { ns: "double_materiality_assessment_sub_category_component" }),
      })),
    [t],
  );
  const filterMaterialityOptions = useMemo(
    () =>
      Object.values(Materiality).map((materiality) => ({
        value: materiality,
        name: t(materiality),
      })),
    [t],
  );

  const generateFilterConfig = useMemo(() => getFilterConfigGenerator(t), [t]);

  const filters: IFilterConfig[] = useMemo(
    () => [
      generateFilterConfig(IrosListFilter.IroType, {
        type: FilterType.Select,
        options: filterIroTypeOptions,
        multiple: true,
      }),
      generateFilterConfig(IrosListFilter.EffectAreaType, {
        type: FilterType.Select,
        options: filterAffectedAreaOptions,
        multiple: true,
      }),
      generateFilterConfig(IrosListFilter.Materiality, {
        type: FilterType.Select,
        options: filterMaterialityOptions,
        multiple: true,
      }),
    ],
    [
      generateFilterConfig,
      filterIroTypeOptions,
      filterAffectedAreaOptions,
      filterMaterialityOptions,
    ],
  );

  const handleFilterChange = useCallback((filterKey: string, value: string | string[]) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filterKey]: value,
    }));
  }, []);

  const filterIros: IFilterIROs = useCallback(
    (iro: IIrosListEntry) => {
      const { esrsTopic, iroType, effectAreaType, materiality } = selectedFilters;
      return DMAOverviewTableUtilities.filterIrosBySelectedFilters(
        iro,
        esrsTopic,
        iroType,
        effectAreaType,
        materiality,
      );
    },
    [selectedFilters],
  );

  return (
    <Box>
      {/* Title */}
      <Typography variant="h3" component="h2" mb={1}>
        {t("title_iro_overview_list")}
      </Typography>
      {/* Filters */}
      <Box display="flex" gap={2} mb={2}>
        {filters.map((filter) => (
          <FilterSelect
            key={filter.name}
            selectedValues={selectedFilters[filter.name as keyof FilterValues]}
            handleChange={(_, value) => handleFilterChange(filter.name, value)}
            filter={filter}
          />
        ))}
      </Box>
      {/* Topics Tabs */}
      <IROsESRSTopicsTabs
        esrsTopics={esrsTopics}
        selectedTopic={selectedFilters.esrsTopic}
        onSelectTopic={(value) =>
          setSelectedFilters((prev) => ({
            ...prev,
            esrsTopic: value,
          }))
        }
      />
      {/* IRO List*/}
      <IROsOverviewList
        esrsTopics={esrsTopics}
        filterIROs={filterIros}
        sortIROs={DMAOverviewTableUtilities.sortIrosByMateriality}
      />
    </Box>
  );
};
