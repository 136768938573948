import { FC, useMemo, useState } from "react";
import { Alert, Box, Button, Typography } from "@mui/material";
import { DownloadIcon } from "../common/constants/tabler-icon.constants";
import { useTranslation } from "react-i18next";
import { InfoDialogText } from "../common/dialogs/variants/info.dialog";
import { IroExportButton } from "../double-materiality-assessment/common/iro-export-button.component";
import { StakeholderFeedbackExportButton } from "../stakeholder-feedbacks/components/stakeholder-feedback-export-button.component";
import { UserExportButton } from "../user/components/user-export-button.component";

interface IExportButtonWithDialogProps {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
}

export const ExportButtonWithDialog: FC<IExportButtonWithDialogProps> = ({
  recordingPeriodId,
  dataEntryObjectId,
  organizationId,
}) => {
  const { t } = useTranslation("export_button_component");
  const [open, setOpen] = useState(false);

  const ExportDialogContent = useMemo(
    () => (
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography variant="body1">{t("download_text")}</Typography>
        <Box display="flex" flexDirection="column" alignItems="start" gap={1}>
          <IroExportButton
            organizationId={organizationId}
            recordingPeriodId={recordingPeriodId}
            dataEntryObjectId={dataEntryObjectId}
          />
          <StakeholderFeedbackExportButton
            organizationId={organizationId}
            recordingPeriodId={recordingPeriodId}
            dataEntryObjectId={dataEntryObjectId}
          />
          <UserExportButton organizationId={organizationId} />
        </Box>
        <Alert severity="info">{t("info")}</Alert>
      </Box>
    ),
    [t, organizationId, recordingPeriodId, dataEntryObjectId],
  );

  return (
    <>
      <InfoDialogText
        title={t("export_data")}
        content={ExportDialogContent}
        open={open}
        onClose={() => setOpen(false)}
      />

      <Button variant="outlined" startIcon={<DownloadIcon />} onClick={() => setOpen(true)}>
        {t("export_data")}
      </Button>
    </>
  );
};
