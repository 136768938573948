import { useMutation } from "@tanstack/react-query";
import { useCurrentLanguage } from "../content-translation/hooks/current-language.hook";
import { CoreApiService } from "../core-api/core-api.service";

export const useExportIrosMutation = (
  organizationId: string,
  recordingPeriodId: string,
  dataEntryObjectId: string,
) => {
  const currentLanguage = useCurrentLanguage();
  return useMutation({
    mutationKey: ["exportIros", organizationId, recordingPeriodId, dataEntryObjectId],
    mutationFn: async () => {
      const request = await CoreApiService.DMAApi.exportDmaIros(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        currentLanguage,
      );
      const result = await request();
      return result.data as unknown as string;
    },
  });
};

export const useExportStakeholderFeedbackMutation = (
  organizationId: string,
  recordingPeriodId: string,
  dataEntryObjectId: string,
) => {
  const currentLanguage = useCurrentLanguage();
  return useMutation({
    mutationKey: [
      "exportStakeholderFeedback",
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
    ],
    mutationFn: async () => {
      const request = await CoreApiService.DMAApi.exportDmaStakeholderFeedbacks(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        currentLanguage,
      );
      const result = await request();
      return result.data as unknown as string;
    },
  });
};

export const useExportUsersMutation = (organizationId: string) => {
  const currentLanguage = useCurrentLanguage();
  return useMutation({
    mutationKey: ["exportUsers", organizationId],
    mutationFn: async () => {
      const request = await CoreApiService.UserApi.exportOrganizationUsers(
        organizationId,
        currentLanguage,
      );
      const result = await request();
      return result.data as unknown as string;
    },
  });
};
