export enum EsrsFilters {
  ResponsiblePerson = "responsiblePerson",
  ContributingPeople = "contributingPeople",
  Status = "status",
  PhaseIn = "phaseIn",
  Optional = "optional",
}

export enum IrosListFilter {
  EsrsTopic = "esrsTopic",
  IroType = "iroType",
  EffectAreaType = "effectAreaType",
  Materiality = "materiality",
}
