import { AppModule, IJwtOrganizationInfo, IJwtPayload } from "./authentication.public-types";
import { UUID } from "../common";

export class AuthenticationUtilities {
  public static getNetCeroOrganizationIds(jwtPayload: IJwtPayload) {
    return AuthenticationUtilities.getNetCeroOrganizations(jwtPayload).map((org) => org.id);
  }

  public static getNetCeroOrganizations(jwtPayload: IJwtPayload) {
    return Object.values(jwtPayload.organization_mapping).map((value) => ({
      id: value.attributes.netcero_id[0],
      name: value.name,
    }));
  }

  /**
   * Extracts the Keycloak organization ID from the JWT payload for the given NetCero organization ID.
   * @param jwtPayload The JWT payload.
   * @param netCeroOrganizationId The NetCero organization ID.
   * @returns The Keycloak organization ID or null if not found.
   */
  public static findKeycloakOrganizationIdForNetCeroOrganizationId(
    jwtPayload: IJwtPayload,
    netCeroOrganizationId: string,
  ): UUID | null {
    const organizationMapping = jwtPayload?.organization_mapping;
    if (!organizationMapping) {
      return null;
    }

    for (const [keycloakOrganizationId, mappingData] of Object.entries(organizationMapping)) {
      if (mappingData.attributes.netcero_id.includes(netCeroOrganizationId)) {
        return keycloakOrganizationId;
      }
    }

    return null;
  }

  /**
   * Extracts the organization data from the JWT payload for the given NetCero organization ID.
   * @param jwtPayload The JWT payload.
   * @param netCeroOrganizationId The NetCero organization ID.
   */
  public static findOrganizationDataForNetCeroOrganizationId(
    jwtPayload: IJwtPayload,
    netCeroOrganizationId: string,
  ) {
    const organizationMapping = jwtPayload?.organization_mapping;
    if (!organizationMapping) {
      return null;
    }

    return (
      Object.values(organizationMapping).find((mappingData) =>
        mappingData.attributes.netcero_id.includes(netCeroOrganizationId),
      ) ?? null
    );
  }

  public static getOrganizationForNetCeroId(
    jwtPayload: IJwtPayload,
    netCeroOrganizationId: string,
  ) {
    const organizations = Object.values(jwtPayload.organization_mapping);
    return (
      organizations.find((org) => org.attributes.netcero_id[0] === netCeroOrganizationId) ?? null
    );
  }

  /**
   * Get the module access for a specific organization
   * @param jwtPayload The JWT payload
   * @param netCeroOrganizationId The organization ID (netcero - NOT keycloak)
   */
  public static getOrganizationModuleAccessNetCero(
    jwtPayload: IJwtPayload,
    netCeroOrganizationId: string,
  ): Record<AppModule, boolean> | null {
    const organization = AuthenticationUtilities.getOrganizationForNetCeroId(
      jwtPayload,
      netCeroOrganizationId,
    );

    if (!organization) {
      return null;
    }

    // Full Access Check
    if (AuthenticationUtilities.checkModuleAccessJwt(organization, "full_access")) {
      return {
        [AppModule.DMA]: true,
        [AppModule.ESRS]: true,
        [AppModule.CARBON_ACCOUNTING]: true,
      };
    }

    // Check Modules
    return {
      [AppModule.DMA]: AuthenticationUtilities.checkModuleAccessJwt(organization, "dma_access"),
      [AppModule.ESRS]: AuthenticationUtilities.checkModuleAccessJwt(organization, "esrs_access"),
      [AppModule.CARBON_ACCOUNTING]: AuthenticationUtilities.checkModuleAccessJwt(
        organization,
        "carbon_accounting_access",
      ),
    };
  }

  /**
   * Get the available modules for a specific organization as an array
   * @param jwtPayload
   * @param netCeroOrganizationId
   */
  public static getOrganizationAvailableModulesNetCero(
    jwtPayload: IJwtPayload,
    netCeroOrganizationId: string,
  ) {
    const organizationAccess = AuthenticationUtilities.getOrganizationModuleAccessNetCero(
      jwtPayload,
      netCeroOrganizationId,
    );
    // Handle organization not in JWT
    if (!organizationAccess) {
      return null;
    }
    // Create Array with available modules
    return (Object.entries(organizationAccess) as [AppModule, boolean][])
      .filter(([, hasAccess]) => hasAccess)
      .map(([key]) => key);
  }

  public static hasOrganizationFullAccessNetCero(
    jwtPayload: IJwtPayload,
    netCeroOrganizationId: string,
  ) {
    const organization = AuthenticationUtilities.getOrganizationForNetCeroId(
      jwtPayload,
      netCeroOrganizationId,
    );
    return organization
      ? AuthenticationUtilities.checkModuleAccessJwt(organization, "full_access")
      : null;
  }

  /**
   * Check if the organization has access to a specific module
   * @param orgInfo The organization info
   * @param key The key to check
   */
  private static checkModuleAccessJwt(
    orgInfo: IJwtOrganizationInfo,
    key: keyof Omit<IJwtOrganizationInfo["attributes"], "netcero_id" | "deo_count_max">,
  ) {
    return orgInfo.attributes[key]?.[0] === "true";
  }
}
