import { Box, Divider } from "@mui/material";
import { IInputParameterRecordingStructureBase } from "@netcero/netcero-core-api-client";
import React, { FC, useMemo } from "react";
import {
  useTranslateContent,
  useTranslateOptionalContent,
} from "../content-translation/hooks/translate-content.hook";
import { EvaluationListItem } from "../evaluation/evaluation-list-item.component";
import { GenericRecordingStructureCard } from "./generic-recording-structure-card.component";
import { useDmaStatusQuery } from "../data-entry-object-values/queries/data-entry-object-dma-status.queries";
import { MaterialityStatusChip } from "../double-materiality-assessment/common/materiality-status-chip.component";
import { useOrganizationRootStructureId } from "../common/hooks/organization-root-deo.hook";
import { DMAUtilities } from "@netcero/netcero-dma";

export interface IRecordingStructureCardProps {
  organizationId: string;
  recordingPeriodId: string;
  recordingStructure: IInputParameterRecordingStructureBase;
  disabled?: boolean;
}

export const RecordingStructureCard: FC<IRecordingStructureCardProps> = ({
  organizationId,
  recordingPeriodId,
  recordingStructure,
  disabled,
}) => {
  const translateContent = useTranslateContent();
  const translateOptionalContent = useTranslateOptionalContent();

  const isEsrsWithMateriality = recordingStructure.categoryIdentifier.startsWith("esrs");

  const organizationRootStructureId = useOrganizationRootStructureId(
    organizationId,
    recordingPeriodId,
  );

  const dmaStatusQuery = useDmaStatusQuery(
    organizationId,
    recordingPeriodId,
    organizationRootStructureId,
    isEsrsWithMateriality,
  );

  const isTopicMaterial = useMemo(() => {
    // Only display materiality for ESRS
    if (!isEsrsWithMateriality) {
      return null;
    }
    // Check if the topic is material
    return dmaStatusQuery.data
      ? DMAUtilities.isTopicInDmaStatusMaterial(
          dmaStatusQuery.data,
          recordingStructure.categoryIdentifier,
          false,
        )
      : null;
  }, [isEsrsWithMateriality, dmaStatusQuery, recordingStructure.categoryIdentifier]);

  return (
    <GenericRecordingStructureCard
      id={recordingStructure.id}
      title={translateContent(recordingStructure.name)}
      subtitle={translateOptionalContent(recordingStructure.description)}
      iconName={recordingStructure.icon}
      url={`recording-structures/${recordingStructure.id}`}
      additionalContent={
        <>
          {isTopicMaterial === null ? null : (
            <Box width="fit-content">
              <MaterialityStatusChip isMateriality={isTopicMaterial} />
            </Box>
          )}
          {recordingStructure.evaluations.length > 0 && (
            <Box>
              <Divider sx={{ my: 1 }} />
              {recordingStructure.evaluations.map((evaluation) => (
                <EvaluationListItem
                  key={evaluation}
                  organizationId={organizationId}
                  recordingPeriodId={recordingPeriodId}
                  inputParameterRecordingStructureId={recordingStructure.id}
                  evaluationKey={evaluation}
                  disabled={disabled}
                />
              ))}
            </Box>
          )}
        </>
      }
      disabled={disabled}
    />
  );
};
