import { FC, ReactNode, useMemo } from "react";
import { Input, InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";
import { IFilterConfig } from "./filter.type";
import { useTranslation } from "react-i18next";

interface IFilterOpenSearchProps {
  filter: IFilterConfig;
  /** URL search parameters for URL-based filtering.
   * Use either searchParams OR value, not both. */
  searchParams?: URLSearchParams;
  /** Value for state-based filtering.
   * Use either value OR searchParams, not both. */
  value?: string;
  handleChange: (name: string, value: string) => void;
  endIcon?: ReactNode;
}

export const FilterOpenSearch: FC<IFilterOpenSearchProps> = ({
  filter,
  searchParams,
  value,
  handleChange,
  endIcon,
}) => {
  const { t } = useTranslation("filter_bar");
  const filterValue = useMemo(() => {
    if (searchParams) {
      return searchParams.get(filter.name) || "";
    }
    return value || "";
  }, [searchParams, value, filter.name]);

  return (
    <Input
      key={filter.name}
      placeholder={filter.label ?? t("search")}
      value={filterValue}
      onChange={(e) => handleChange(filter.name, e.target.value)}
      size="small"
      endAdornment={
        <InputAdornment position="end">
          {endIcon ?? <Search sx={{ color: "rgba(0, 0, 0, 0.5)" }} />}
        </InputAdornment>
      }
      sx={{
        alignItems: "center",
        justifyContent: "center",
        height: "32px",
        maxWidth: "140px",
        "& input": {
          fontSize: "14px",
          padding: 0,
          marginBottom: -0.5,
        },
      }}
    />
  );
};
