import { FC, useCallback } from "react";
import { DmaUtilities } from "@netcero/netcero-common";
import { FileUtilities } from "../../common/utilities/file.utilities";
import { useAppSnackbar } from "../../app-snackbar/app-snackbar.hook";
import {
  BasicSnackbarApiActionType,
  IAppSnackbarEnqueueBasicSuccessMessageData,
} from "../../app-snackbar/app-snackbar.interfaces";
import { ExportButton } from "../../export/export-button.component";
import { ExportTypes } from "../../export/export.constants";
import { useExportStakeholderFeedbackMutation } from "../../export/export.mutations";

interface IStakeholderFeedbackExportButtonProps {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
}

const EXPORT_DMA_STAKEHOLDER_FEEDBACK_ACTION: IAppSnackbarEnqueueBasicSuccessMessageData = {
  type: BasicSnackbarApiActionType.EXPORT_DMA_STAKEHOLDER_FEEDBACK,
};

export const StakeholderFeedbackExportButton: FC<IStakeholderFeedbackExportButtonProps> = ({
  recordingPeriodId,
  dataEntryObjectId,
  organizationId,
}) => {
  const appSnackbar = useAppSnackbar();

  const { mutateAsync, isPending } = useExportStakeholderFeedbackMutation(
    organizationId,
    recordingPeriodId,
    dataEntryObjectId,
  );

  const handleDownload = useCallback(async () => {
    await appSnackbar.wrapApiPromise(async () => {
      const data = await mutateAsync();
      // rather hacky, but the generator determines that the return type is "File",
      // while in reality, a string is returned...
      const asBlob = new Blob([data], { type: "text/csv" });
      FileUtilities.downloadBlobAsFile(DmaUtilities.getStakeholderFeedbackExportFileName(), asBlob);
    }, EXPORT_DMA_STAKEHOLDER_FEEDBACK_ACTION);
  }, [appSnackbar, mutateAsync]);

  return (
    <ExportButton
      handleDownload={handleDownload}
      exportType={ExportTypes.StakeholderFeedback}
      isLoading={isPending}
    />
  );
};
