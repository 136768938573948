/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "../configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base";
// @ts-ignore
import { IContentLanguage } from "../models";
// @ts-ignore
import { IGetAllInputParameterValueListValues404Response } from "../models";
/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Exports all users of the given organization
     * @summary Exports all users of the given organization
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {IContentLanguage} language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportOrganizationUsers: async (
      organizationId: string,
      language: IContentLanguage,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("exportOrganizationUsers", "organizationId", organizationId);
      // verify required parameter 'language' is not null or undefined
      assertParamExists("exportOrganizationUsers", "language", language);
      const localVarPath = `/organizations/{organizationId}/users/export`.replace(
        `{${"organizationId"}}`,
        encodeURIComponent(String(organizationId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration);
  return {
    /**
     * Exports all users of the given organization
     * @summary Exports all users of the given organization
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {IContentLanguage} language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportOrganizationUsers(
      organizationId: string,
      language: IContentLanguage,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exportOrganizationUsers(
        organizationId,
        language,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UsersApiFp(configuration);
  return {
    /**
     * Exports all users of the given organization
     * @summary Exports all users of the given organization
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {IContentLanguage} language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportOrganizationUsers(
      organizationId: string,
      language: IContentLanguage,
      options?: any,
    ): AxiosPromise<File> {
      return localVarFp
        .exportOrganizationUsers(organizationId, language, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UsersApi - interface
 * @export
 * @interface UsersApi
 */
export interface UsersApiInterface {
  /**
   * Exports all users of the given organization
   * @summary Exports all users of the given organization
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {IContentLanguage} language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  exportOrganizationUsers(
    organizationId: string,
    language: IContentLanguage,
    options?: AxiosRequestConfig,
  ): AxiosPromise<File>;
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI implements UsersApiInterface {
  /**
   * Exports all users of the given organization
   * @summary Exports all users of the given organization
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {IContentLanguage} language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public exportOrganizationUsers(
    organizationId: string,
    language: IContentLanguage,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .exportOrganizationUsers(organizationId, language, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
