import { Box, SvgIcon, Tooltip } from "@mui/material";
import {
  CornerDownRightDoubleIcon,
  CornerDownRightUpDoubleIcon,
} from "../../common/constants/tabler-icon.constants";
import { OrganizationOctagonIcon } from "../../common/constants/iconify-icon.constants";
import { useTranslation } from "react-i18next";
import { IDMAEffectData } from "@netcero/netcero-core-api-client";
import { FC } from "react";

interface IDEOAreasComponentProps {
  typeOfIRO: Pick<IDMAEffectData, "areas" | "associatedDataEntryObjects">;
}

export const IroAreasComponent: FC<IDEOAreasComponentProps> = ({ typeOfIRO }) => {
  const { t } = useTranslation("double_materiality_assessment_sub_category_component");

  return (
    <Box display="flex" gap={1} justifyContent="center" alignItems="center">
      {typeOfIRO.areas.includes("upstream-value-chain") && (
        <Tooltip title={t("upstream-value-chain_name")}>
          <SvgIcon
            color="action"
            component={CornerDownRightUpDoubleIcon}
            sx={{ fill: "transparent", height: 20, width: 20 }}
          />
        </Tooltip>
      )}
      {typeOfIRO.associatedDataEntryObjects.length > 0 && (
        <Tooltip title={t("own-organization_name")}>
          <SvgIcon color="action" sx={{ width: 20, height: 20, padding: 0.25 }}>
            <OrganizationOctagonIcon />
          </SvgIcon>
        </Tooltip>
      )}
      {typeOfIRO.areas.includes("downstream-value-chain") && (
        <Tooltip title={t("downstream-value-chain_name")}>
          <SvgIcon
            color="action"
            component={CornerDownRightDoubleIcon}
            sx={{ fill: "transparent", height: 20, width: 20 }}
          />
        </Tooltip>
      )}
    </Box>
  );
};
