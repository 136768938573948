import { Box, Button, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import { FC, PropsWithChildren, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconSize, PlusIcon } from "../common/constants/tabler-icon.constants";
import { InputParameterRecordingStructuresUtilities } from "../input-parameter-recording-structures/input-parameter-recording-structures.utilities";
import { useCurrentOrganization } from "../organizations/use-current-organization.hook";
import { useRecordingModelsQuery } from "../recording-models/recording-models.queries";
import { useCurrentRecordingPeriod } from "../recording-periods/use-current-recording-period.hook";
import { AddRecordingStructureToRecordingPeriodDialog } from "../recording-structures/add-recording-structure-to-recording-period.dialog";
import { RecordingStructureCard } from "../recording-structures/recording-structure-card.component";
import { useRecordingPeriodRecordingStructuresQuery } from "../recording-structures/recording-structures.queries";
import { useTranslateOptionalContent } from "../content-translation/hooks/translate-content.hook";
import { AppModule } from "@netcero/netcero-common";
import { useHasOrganizationModuleAccess } from "../organization-module-access/has-organization-module-access.hook";
import { GenericRecordingStructureCard } from "../recording-structures/generic-recording-structure-card.component";
import {
  IInputParameterRecordingStructureBase,
  IRecordingModel,
} from "@netcero/netcero-core-api-client";
import { QueriesWrapper } from "../common/components/queries-wrapper.component";
import { ConfirmDialogTextBody } from "../common/dialogs/variants/confirm.dialog";
import { useInitEsrsRecordingMutation } from "../double-materiality-assessment/mutations/dma.mutations";
import { useAppSnackbar } from "../app-snackbar/app-snackbar.hook";
import {
  BasicSnackbarApiActionType,
  IAppSnackbarEnqueueApiMessageAction,
} from "../app-snackbar/app-snackbar.interfaces";
import { useResetMutationsOnOpen } from "../common/hooks/use-reset-mutations-on-open.hook";
import { EnvironmentUtilities } from "../common/utilities/environment.utilities";

const INIT_ALL_ESRS_TOPICS_ACTION: IAppSnackbarEnqueueApiMessageAction = {
  type: BasicSnackbarApiActionType.INIT_ALL_ESRS_TOPICS,
};

const ADD_TOPICS_BUTTON_PROPS = {
  variant: "outlined",
  color: "primary",
  sx: {
    display: "flex",
    gap: 1,
    borderRadius: "24px",
    width: "100%",
    minHeight: "134px",
  },
} as const;

interface IAddRecordingStructureDialogState {
  open: boolean;
  recordingModelId: string | null;
}

interface IRecordingPeriodRecordingStructuresCardsListProps {
  organizationId: string;
  recordingPeriodId: string;
}

export const RecordingPeriodRecordingStructuresCardsList: FC<
  IRecordingPeriodRecordingStructuresCardsListProps
> = ({ organizationId, recordingPeriodId }) => {
  const { t } = useTranslation("recording_period_recording_structures_cards_list");
  const { wrapApiPromise } = useAppSnackbar();

  const currentOrganization = useCurrentOrganization();
  const currentRecordingPeriod = useCurrentRecordingPeriod();

  const [showConfirmInitEsrs, setShowConfirmInitEsrs] = useState(false);

  const hasAccessToEsrs = useHasOrganizationModuleAccess(organizationId, AppModule.ESRS);

  const recordingModelsQuery = useRecordingModelsQuery(organizationId);
  const recordingStructuresQuery = useRecordingPeriodRecordingStructuresQuery(
    organizationId!,
    recordingPeriodId!,
  );

  const recordingStructuresGroupedByRecordingModel = useMemo(() => {
    // Wait for data to become available
    if (!recordingStructuresQuery.data || !recordingModelsQuery.data) {
      return [];
    }

    const result: [IRecordingModel | null, IInputParameterRecordingStructureBase[]][] = [
      ...InputParameterRecordingStructuresUtilities.groupRecordingStructuresByRecordingModelId(
        recordingStructuresQuery.data.recordingStructures,
      ),
    ].map(([modelId, structures]) => [
      recordingModelsQuery.data.recordingModels.find((model) => model.id === modelId) ?? null,
      structures,
    ]);

    // Add ESRS model if not present
    if (hasAccessToEsrs && !result.some(([model, structures]) => model?.identifier === "esrs")) {
      // Add ESRS model if not present
      const esrsModel = recordingModelsQuery.data.recordingModels.find(
        (model) => model.identifier === "esrs",
      );
      if (esrsModel) {
        result.push([esrsModel, []]);
      }
    }

    // At least add empty array for recording structures without recording model
    if (result.length === 0) {
      result.push([null, []]);
    }

    return result;
  }, [hasAccessToEsrs, recordingModelsQuery.data, recordingStructuresQuery.data]);

  const [showAddInputParameterModelDialog, setShowAddInputParameterModelDialog] =
    useState<IAddRecordingStructureDialogState>({ open: false, recordingModelId: null });

  const categoryIdentifiersInUse = useMemo(
    () =>
      new Set(
        recordingStructuresQuery.data?.recordingStructures.map(
          (recordingStructure) => recordingStructure.categoryIdentifier,
        ) || [],
      ),
    [recordingStructuresQuery.data?.recordingStructures],
  );

  // Disabled for now since only ESRS after DMA is possible for now
  const handleOpenAddInputParameterModelDialog = (recordingModelId: string | null) => {
    setShowAddInputParameterModelDialog({
      open: true,
      recordingModelId,
    });
  };

  const orderNumbersInUse = useMemo(
    () =>
      recordingStructuresQuery.data
        ? InputParameterRecordingStructuresUtilities.extractRecordingModelsOrderValues(
            recordingStructuresQuery.data.recordingStructures,
          )
        : new Map<string | null, Set<number>>(),
    [recordingStructuresQuery],
  );

  const initEsrsRecordingMutation = useInitEsrsRecordingMutation();
  const handleInitEsrsRecording = useCallback(
    async (confirm: boolean) => {
      if (confirm) {
        await wrapApiPromise(
          initEsrsRecordingMutation.mutateAsync({
            organizationId,
            recordingPeriodId,
          }),
          INIT_ALL_ESRS_TOPICS_ACTION,
        );
      }
      setShowConfirmInitEsrs(false);
    },
    [initEsrsRecordingMutation, organizationId, recordingPeriodId, wrapApiPromise],
  );
  useResetMutationsOnOpen(showConfirmInitEsrs, initEsrsRecordingMutation);

  return (
    <>
      {currentOrganization && currentRecordingPeriod && (
        <AddRecordingStructureToRecordingPeriodDialog
          open={showAddInputParameterModelDialog.open}
          onClose={() =>
            setShowAddInputParameterModelDialog({ open: false, recordingModelId: null })
          }
          organization={currentOrganization}
          recordingPeriod={currentRecordingPeriod}
          categoryIdentifiersInUse={categoryIdentifiersInUse}
          orderNumbersInUse={orderNumbersInUse}
          initiallySelectedRecordingModelId={showAddInputParameterModelDialog.recordingModelId}
        />
      )}
      {/* Confirm Start ESRS Dialog */}
      <ConfirmDialogTextBody
        open={showConfirmInitEsrs}
        loading={initEsrsRecordingMutation.isPending}
        error={initEsrsRecordingMutation.error}
        disabled={initEsrsRecordingMutation.isPending}
        title={t("init_esrs_confirm_dialog.title")}
        content={t("init_esrs_confirm_dialog.content")}
        onClose={handleInitEsrsRecording}
      />
      {/* Render List */}
      <QueriesWrapper
        queries={[recordingModelsQuery, recordingStructuresQuery]}
        loadingOverride={() => (
          <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
            <CircularProgress />
          </Box>
        )}
        build={([recordingModels, recordingStructures]) => (
          <>
            {recordingStructuresGroupedByRecordingModel.map(([model, structures]) => (
              <RecordingModelList
                key={model?.id ?? null}
                organizationId={organizationId}
                recordingPeriodId={recordingPeriodId}
                recordingModel={model ?? null}
                structures={structures}
                onOpenAddInputParameterModelDialog={handleOpenAddInputParameterModelDialog}
                onInitializeEsrsRecording={() => setShowConfirmInitEsrs(true)}
              />
            ))}
          </>
        )}
      />
    </>
  );
};

interface IRecordingModelListProps {
  organizationId: string;
  recordingPeriodId: string;
  recordingModel: IRecordingModel | null;
  structures: IInputParameterRecordingStructureBase[];
  onOpenAddInputParameterModelDialog: (recordingModelId: string | null) => void;
  onInitializeEsrsRecording: VoidFunction;
}

export const RecordingModelList: FC<IRecordingModelListProps> = ({
  organizationId,
  recordingPeriodId,
  recordingModel,
  structures,
  onOpenAddInputParameterModelDialog,
  onInitializeEsrsRecording,
}) => {
  const { t } = useTranslation([
    "recording_period_recording_structures_cards_list",
    "recording_period_actions_dashboard_section",
    "gap_analysis",
  ]);
  const translateContent = useTranslateOptionalContent();
  const hasAccessToDMA = useHasOrganizationModuleAccess(organizationId, AppModule.DMA);
  const hasAccessToEsrs = useHasOrganizationModuleAccess(organizationId, AppModule.ESRS);

  const isEsrs = recordingModel?.identifier === "esrs";

  const showStartEsrsRecording =
    isEsrs &&
    // Ensure access to ESRS
    hasAccessToEsrs &&
    // Only available if not all ESRS structures exist yet (ESRS 2, E1-5, S1-4, G1)
    structures.length < 11;

  const isProduction = EnvironmentUtilities.IS_PRODUCTION;

  return (
    <Box mb={4}>
      <Typography variant="h2">
        {translateContent(recordingModel?.name) ?? t("structures_without_recording_model_header")}
      </Typography>
      <Divider />

      <Grid container spacing={2} columnSpacing={3} mt={2} alignItems="stretch">
        {isEsrs && structures.length > 0 && (
          <>
            {hasAccessToDMA && (
              <RecordingStructureCardGridItem>
                <GenericRecordingStructureCard
                  id="dma"
                  title={t(
                    "recording_period_actions_dashboard_section:double_materiality_assessment_abbreviation",
                  )}
                  subtitle={t(
                    "recording_period_actions_dashboard_section:title_double_materiality_assessment",
                  )}
                  iconName="ph:rows"
                  url={`/organizations/${organizationId}/recording-periods/${recordingPeriodId}/double-materiality-assessment`}
                />
              </RecordingStructureCardGridItem>
            )}
            <RecordingStructureCardGridItem>
              <GenericRecordingStructureCard
                id="gap-analysis"
                title={t("gap_analysis:title")}
                subtitle={t("gap_analysis:subtitle")}
                iconName="tabler:file-text"
                url={`/organizations/${organizationId}/recording-periods/${recordingPeriodId}/gap-analysis`}
              />
            </RecordingStructureCardGridItem>
          </>
        )}

        {/* Existing recording structures */}
        {structures.map((recordingStructure) => (
          <RecordingStructureCardGridItem key={recordingStructure.id}>
            <RecordingStructureCard
              organizationId={organizationId ?? ""}
              recordingPeriodId={recordingPeriodId ?? ""}
              recordingStructure={recordingStructure}
              disabled={isProduction && recordingStructure.categoryIdentifier === "esrs_e1"}
            />
          </RecordingStructureCardGridItem>
        ))}
        {/* Button to add all ESRS topics */}
        {showStartEsrsRecording && (
          <Grid item xs={12} md={6} lg={4} display="flex" justifyContent="stretch">
            <Button {...ADD_TOPICS_BUTTON_PROPS} onClick={() => onInitializeEsrsRecording()}>
              <PlusIcon size={IconSize.Larger} />
              <Typography color="primary">{t("add_all_esrs_topics_button")}</Typography>
            </Button>
          </Grid>
        )}
        {/* Button to add new recording structure */}
        <Grid item xs={12} md={6} lg={4} display="flex" justifyContent="stretch">
          <Button
            {...ADD_TOPICS_BUTTON_PROPS}
            onClick={() => onOpenAddInputParameterModelDialog(recordingModel?.id ?? null)}
          >
            <PlusIcon size={IconSize.Larger} />
            <Typography color="primary">{t("add_category_button")}</Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const RecordingStructureCardGridItem: FC<PropsWithChildren> = ({ children }) => (
  <Grid item xs={12} sm={6} lg={4}>
    {children}
  </Grid>
);
