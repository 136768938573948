import { IrosListFilter } from "../../filters/filter.constants";

export enum EffectAreaType {
  OwnOrganization = "own-organization",
  UpstreamValueChain = "upstream-value-chain",
  DownstreamValueChain = "downstream-value-chain",
}

export enum Materiality {
  Material = "materiality_value_true",
  NotMaterial = "materiality_value_false",
}

export type FilterValues = {
  [IrosListFilter.IroType]: string[];
  [IrosListFilter.EffectAreaType]: string[];
  [IrosListFilter.Materiality]: string[];
};
