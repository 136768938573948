import { FC } from "react";
import { Button, CircularProgress, Tooltip } from "@mui/material";
import { DownloadIcon } from "../common/constants/tabler-icon.constants";
import { useTranslation } from "react-i18next";
import { useCurrentLanguage } from "../content-translation/hooks/current-language.hook";
import { ExportTypes } from "./export.constants";

interface IExportButtonProps {
  handleDownload: () => void;
  exportType: ExportTypes;
  isLoading?: boolean;
}

export const ExportButton: FC<IExportButtonProps> = ({ handleDownload, exportType, isLoading }) => {
  const { t } = useTranslation("export_button_component");
  const currentLanguage = useCurrentLanguage();
  return (
    <Tooltip
      title={t("button_tooltip", {
        context: exportType,
        currentLanguage: t(`translationsPerLanguage.${currentLanguage}`, {
          ns: "language",
        }),
      })}
      placement="right" // To not hinder user of clicking next button (below)
    >
      <Button
        variant="text"
        startIcon={<DownloadIcon />}
        onClick={handleDownload}
        disabled={isLoading}
        sx={{ gap: 1 }}
      >
        {t("button_content", { context: exportType })}
        {isLoading && <CircularProgress size={18} />}
      </Button>
    </Tooltip>
  );
};
