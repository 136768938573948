import { useMemo } from "react";
import { useUserContext } from "../user/user.context";
import { IOrganization } from "@netcero/netcero-core-api-client";
import { AuthenticationUtilities } from "@netcero/netcero-common";

/**
 * Gets all organizations the current user has access to.
 * Returns an empty array if the user is not yet logged in.
 */
export function useOrganizations(): IOrganization[] {
  const { user } = useUserContext();

  return useMemo<IOrganization[]>(() => {
    if (!user) {
      return [];
    }

    return AuthenticationUtilities.getNetCeroOrganizations(user.jwtPayload);
  }, [user]);
}
